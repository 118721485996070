define("itarp-opportunity-ui/routes/my-opportunities", ["exports", "@ember/routing/route", "@ember/service", "rsvp", "@ember/object", "itarp-opportunity-ui/utils/opportunity"], function (_exports, _route, _service, _rsvp, _object, _opportunity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MyOpportunitiesRoute = (_dec = (0, _service.inject)('current-user'), _dec2 = (0, _service.inject)('filters'), (_class = class MyOpportunitiesRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "currentUser", _descriptor2, this);

      _initializerDefineProperty(this, "filtersService", _descriptor3, this);

      _defineProperty(this, "queryParams", {
        page: {
          refreshModel: true
        },
        ..._opportunity.OpportunityParams,
        practice_areas: {
          refreshModel: true
        }
      });

      _defineProperty(this, "staleParams", null);

      _defineProperty(this, "initialLoad", true);
    }

    buildRouteInfoMetadata() {
      return {
        title: 'My Job Opportunities',
        padding: {
          top: '6-5'
        }
      };
    }

    async model(params) {
      const shouldResetPage = this.shouldResetPageParams({ ...params
      });
      if (shouldResetPage) params.page = _opportunity.DEFAULT_PAGE_NUMBER;
      const pageNumber = params.page;
      const filters = this.filtersService.buildQuery(this, { ...params
      });
      const keywords = this.getKeywords(filters, { ...params
      });
      let filter = params.practice_areas ? {
        practice_areas: params.practice_areas
      } : {};
      const user = await this.currentUser.information;
      const opportunityParams = {
        keywords: keywords ? JSON.parse(keywords) : [],
        filters,
        filter,
        search_required: true,
        page: pageNumber ? pageNumber : _opportunity.DEFAULT_PAGE_NUMBER,
        per_page: _opportunity.RECORDS_PER_PAGE,
        adapterOptions: {
          userApplied: true
        },
        order_by: ['updated_at', 'desc']
      };

      if (!opportunityParams.keywords.length) {
        opportunityParams['page'] = pageNumber ? pageNumber : _opportunity.DEFAULT_PAGE_NUMBER;
      } else {
        delete opportunityParams['page'];
      }

      const selectedPracticeAreas = this.loadSelectedPracticeAreas(params.practice_areas);
      const appliedOpportunities = await this.store.query('applied-opportunity', opportunityParams);
      return (0, _rsvp.hash)({
        appliedOpportunities,
        user,
        favorites: this.store.query('user-favorite', {
          filter: {
            user_id: user.id,
            resource_type: 'opportunity'
          }
        }),
        isDefaultParamsApplied: this.isDefaultParametersApplied(params),
        selectedPracticeAreas,
        shouldResetPage
      });
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.set('projects', model.appliedOpportunities);
      controller.set('total', model.appliedOpportunities.length);
      controller.set('noRecords', model.appliedOpportunities.length === 0);

      if (model.shouldResetPage) {
        controller.set('page', _opportunity.DEFAULT_PAGE_NUMBER);
      }

      if (model.appliedOpportunities.length > 0) {
        const selectedProject = model.appliedOpportunities.get('firstObject');
        controller.set('selectedProject', selectedProject);
        controller.set('opportunityId', selectedProject.opportunityId);
      } else {
        controller.set('selectedProject', null);
      }

      controller.set('filterItems', { ...this.filterItems
      });
      controller.set('filterChips', this.filterChips);
      controller.set('filterSchema', this.queryParams);
      controller.set('isDefaultParamsApplied', model.isDefaultParamsApplied);
      controller.set('updateQueryParams', this.updateQueryParams);
      controller.set('selectedPracticeAreas', model.selectedPracticeAreas);

      if (model.favorites && model.favorites.length > 0) {
        controller.set('userFavorites', model.favorites.get('firstObject').resourceIds);
      } else {
        controller.set('userFavorites', []);
      }
    }

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('page', _opportunity.DEFAULT_PAGE_NUMBER);
        ['job_type', 'open_to_remote', 'keywords', 'location', 'skills'].map(prop => controller.set(prop, null));
        this.initialLoad = true;
        this.filterItems = this.filtersService.setupFilters(this);
      }
    }

    beforeModel() {
      if (this.filterItems) return;
      const route = this;
      this.filterItems = this.filtersService.setupFilters(route);
    }

    afterModel(model) {
      const chips = this.filtersService.deserializeFilters(model.params, this.filterItems, this);
      this.initialLoad = false;
      this.filterChips = chips;
    }

    loading() {
      return this.initialLoad;
    }

    getKeywords(filters, {
      keywords
    }) {
      var _filter$skills;

      const filter = filters.find(f => Object.keys(f).includes('skills'));
      let value = keywords || '';

      if (filter && ((_filter$skills = filter.skills) === null || _filter$skills === void 0 ? void 0 : _filter$skills.length) > 0) {
        filters.removeObject(filter);
        const skills = filter.skills.join(',');
        value = value ? value.concat(',', skills) : skills;
      }

      let result = this.filterItems.keywords.serializeSearch(value);
      return result;
    }

    isDefaultParametersApplied(params) {
      // There are default values for status and page. So ignore it
      const paramsWithoutStatus = { ...params
      };
      delete paramsWithoutStatus.status;
      delete paramsWithoutStatus.page;
      const res = Object.keys(paramsWithoutStatus).every(key => {
        return params[key] === null;
      });
      return res;
    }

    updateQueryParams() {
      const route = this;
      this.filtersService.serializeFilters(this.filterItems, route);
    }

    parametersMatch(params) {
      if (!this.staleParams) return true;
      const currentParams = Object.assign({}, params);
      const asString = JSON.stringify;
      delete currentParams.page;
      delete this.staleParams.page;
      return asString(currentParams) === asString(this.staleParams);
    }

    shouldResetPageParams(params) {
      const shouldReset = this.parametersMatch(params) !== true;
      this.staleParams = Object.assign({}, params);
      return shouldReset;
    }

    loadSelectedPracticeAreas(practiceAreas) {
      if (practiceAreas && practiceAreas.length > 0) {
        const ids = practiceAreas === null || practiceAreas === void 0 ? void 0 : practiceAreas.split(',');
        const inStore = this.store.peekAll('practice-area');

        if (ids.every(id => inStore.findBy('id', id))) {
          return inStore.filter(pa => ids.includes(pa.id));
        } else {
          return this.store.query('practice-area', {
            advance_filter: {
              id: {
                $in: ids
              }
            }
          });
        }
      }

      return [];
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "filtersService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "beforeModel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "beforeModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterModel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "afterModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loading", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loading"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getKeywords", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getKeywords"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateQueryParams", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateQueryParams"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "parametersMatch", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "parametersMatch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "shouldResetPageParams", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "shouldResetPageParams"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadSelectedPracticeAreas", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadSelectedPracticeAreas"), _class.prototype)), _class));
  _exports.default = MyOpportunitiesRoute;
});