define("itarp-talentcloud-ui/controllers/details", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "@ember/service"], function (_exports, _controller, _object, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DetailsController = (_class = class DetailsController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "workflows", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "loading", _descriptor4, this);

      _initializerDefineProperty(this, "newHourlyRate", _descriptor5, this);

      _initializerDefineProperty(this, "isPreferredRateSaved", _descriptor6, this);

      _initializerDefineProperty(this, "isApplyOpen", _descriptor7, this);

      _initializerDefineProperty(this, "isApplied", _descriptor8, this);

      _initializerDefineProperty(this, "activeTab", _descriptor9, this);

      _initializerDefineProperty(this, "applicationErrors", _descriptor10, this);

      _initializerDefineProperty(this, "errors", _descriptor11, this);

      _initializerDefineProperty(this, "previousRoute", _descriptor12, this);

      _initializerDefineProperty(this, "_talentCloudId", _descriptor13, this);

      _defineProperty(this, "queryParams", ['referrer']);

      _initializerDefineProperty(this, "referrer", _descriptor14, this);

      _initializerDefineProperty(this, "isCloudMember", _descriptor15, this);
    }

    changeActiveTab(tabName) {
      this.activeTab = tabName;
    }

    registerOffscreenAPI(options) {
      this.applyOffscreen = options;
    }

    updateHourlyRate(val) {
      this.newHourlyRate = val;
    }

    savePreferredRate(val) {
      this.isPreferredRateSaved = true;
    }

    async apply(submitModal) {
      this.application.resourceId = this.talentCloud.id;
      this.application.resourceType = 'talent_cloud';
      this.application.name = this.user.fullName;
      this.application.email = this.user.email;
      this.application.userId = this.user.id;
      this.application.companyId = this.companyId;
      this.application.isInvited = !!this.invitation;
      const stageId = await this.workflows.firstStageFor(this.talentCloud, this.application.isInvited).then(stage => {
        if (stage) return stage.id;
      });
      if (stageId) this.application.workflowStageId = stageId;

      if (this.isPreferredRateSaved && this.newHourlyRate) {
        this.user.profile.hourlyRate = this.newHourlyRate;
        this.user.save();
      }

      this.application.save().then(() => {
        this.isApplyOpen = false;
        this.isApplied = true;

        if (this.user.profiles.get('resume')) {
          this.application.set('resume', this.user.profiles.get('resume'));
        }

        submitModal.open();
      }).catch(error => {
        this.applicationErrors.pushObject('Your application cannot be submitted, Make sure you have entered a valid rate and uploaded resume');
      });
    }

    set talentCloudId(talentCloudId) {
      this._talentCloudId = talentCloudId;
    }

    get talentCloudId() {
      return this._talentCloudId;
    }

    get referrer() {
      let encodedUrl = btoa(`${window.location.pathname}`);
      return `${encodedUrl.replaceAll('/', '-')}`;
    }

    get originatedFromTitle() {
      const originatedFrom = this.referrer ? atob(this.referrer.replaceAll('-', '/')) : null;

      if (originatedFrom) {
        const title = originatedFrom.split('/').pop().split('?')[0];

        switch (title) {
          case 'my-talentclouds':
            return 'Back to My TalentClouds';

          case 'invites':
            return 'Back to TalentCloud Invitations';

          case 'interviews':
            return 'Back to Interviews';

          case 'workrooms':
            return 'Back to My Workrooms';

          case 'my-favorite-talentclouds':
            return 'Back to My Favorite TalentClouds';

          default:
            return 'Back to Browse TalentClouds';
        }
      }

      return 'Back to Browse TalentClouds';
    }

    navigateTo(route, e) {
      e.preventDefault();
      this.router.transitionTo(route);
    }

    toggleReason(value) {
      if (this.invitation.reason === value) {
        this.invitation.reason = null;
      } else {
        this.invitation.reason = value;
      }
    }

    declineInvitation(dropdown) {
      const data = {
        invitations: {
          reason: this.invitation.reason,
          status: 'declined'
        }
      };

      try {
        fetch(`/api/expert/api/talentcloud/routing/data_service/expert/invitations/decline/${this.invitation.id}`, {
          method: 'PATCH',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('itarp_token')
          }
        }).then(res => res.json()).then(json => {
          this.invitation.status = json.status;
          dropdown.actions.close();
        });
      } catch (error) {
        this.errors.pushObject(error);
      }
    }

    redirectTo() {
      this.loading.show();
      const originatedFrom = this.referrer ? atob(this.referrer.replaceAll('-', '/')) : null;

      if (originatedFrom) {
        this.router.transitionTo(originatedFrom);
      } else if (this.previousRoute) {
        this.router.transitionTo(this.previousRoute);
      } else {
        this.router.transitionTo(`browse`);
      }
    }

    updateUserFavorites(updatedUserFavorites) {
      (0, _object.set)(this, 'userFavorites', updatedUserFavorites);
    }

    evaluateIsMember() {
      if (!this.application) {
        this.isCloudMember = false;
      } else {
        const workflowStageId = this.application.workflowStageId;
        const workflowId = this.talentCloud.workflowId;

        if (!workflowStageId || !workflowId) {
          this.isCloudMember = false;
          return;
        }

        this.workflows.findStageByStageId(workflowId, workflowStageId).then(stage => {
          if (stage) {
            this.isCloudMember = stage.stageType === 'offer_accepted';
          } else {
            this.isCloudMember = false;
          }
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "workflows", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "loading", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "newHourlyRate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isPreferredRateSaved", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isApplyOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isApplied", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "activeTab", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "applicationErrors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "errors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "previousRoute", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "_talentCloudId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "referrer", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "isCloudMember", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "changeActiveTab", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeActiveTab"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerOffscreenAPI", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerOffscreenAPI"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateHourlyRate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateHourlyRate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "savePreferredRate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "savePreferredRate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "apply", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "apply"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "navigateTo", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "navigateTo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleReason", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleReason"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "declineInvitation", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "declineInvitation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "redirectTo", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "redirectTo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateUserFavorites", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateUserFavorites"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "evaluateIsMember", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "evaluateIsMember"), _class.prototype)), _class);
  _exports.default = DetailsController;
});